<div class="demo-layout">
  <app-header></app-header>

  <main class="demo-main" style="box-sizing: border-box;">
    @if(!!(sessionService.userData$ | async)?.sessionUID) {
      <router-outlet></router-outlet>
    } @else {
      <div>Please sign in.</div>
    }
  </main>
</div>
