import { Delivery } from 'src/app/models/delivery';
import { GraphqlDeliveryOutput } from './../models/output/delivery.output.model';
import { GraphqlDeliveryInput } from 'src/app/graphql/models/input/delivery.input.model';

export function convertGrapqlDeliveryOutputToDelivery(input: GraphqlDeliveryOutput): Delivery | undefined {

  if (!input) {
    return undefined
  }
  return {
    Name: input.Name,
    Description: input.Description,
    UID: input.UID,
    RegTime: new Date(input.RegTime),
    RegBy: input.RegBy,
    LatestVersion: input.LatestVersion,
    Version: input.Version
  } as Delivery
}

export function convertDeliveryToGraphqlDeliveryInput(input: Delivery): GraphqlDeliveryInput | undefined {

  if (!input) {
    return undefined
  }

  return {
    Name: input.Name,
    Description: input.Description,
    UID: input.UID
  } as GraphqlDeliveryInput
}
