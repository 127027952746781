import { Injectable } from '@angular/core';

import { Observable, map, shareReplay, switchMap, take } from 'rxjs';
import { FetchResult } from '@apollo/client/core';

import { testCalendarQueries } from '../graphql/queries/testCalendarQueries';
import { TestCalendar, TestWeek } from '../models/testCalendar';
import { testWeekMutations } from '../graphql/mutations/testWeekMutations';
import { ApolloClientService } from 'src/app/services/apollo-client.service';

@Injectable({
  providedIn: 'root'
})

export class TestCalendarService {

  public allSOPs$ = this.apolloClientService.abstractionLayerClient$.pipe(
    switchMap(client =>
      client.query({
        query: testCalendarQueries.getAllTestCalendarsByTypeQuery,
        variables: { TypeId: 6 },
        errorPolicy: "all"
      }),
    ),
    map((res: any) => {
      return res.data.testCalendarsByType
    }),
    take(1),
    shareReplay(1),
  )

  public allTestWeeks$ = this.apolloClientService.abstractionLayerClient$.pipe(
    switchMap(client =>
      client.query({
        query: testCalendarQueries.getAllTestCalendarsByTypeQuery,
        variables: { TypeId: 7 },
        errorPolicy: "all"
      })
    ),
    map((res: any) => {
      return res.data.testCalendarsByType
    }),
    take(1),
    shareReplay(1)
  )

  constructor(private apolloClientService: ApolloClientService) { }

  updateTestWeek$(testWeek: TestWeek): Observable<FetchResult<unknown>> {
    return this.apolloClientService.appSyncDbClient$.pipe(
      switchMap(client =>
        client.mutate({
          mutation: testWeekMutations.addOrUpdateTestWeek,
          variables: {
            id: testWeek.Id,
            name: testWeek.Name,
            validFrom: testWeek.StartUtc,
            validTo: testWeek.EndUtc
          },
        })
      )
    )
  }

  getTestWeekByStartDate(testCalendar: TestCalendar[], selectedDate: Date): TestCalendar {
    const validFrom = new Date(selectedDate)
    return testCalendar.find(tw => new Date(tw.StartDate) <= validFrom&& new Date(tw.EndDate) >= validFrom) ?? <TestCalendar>{};
  }
}
