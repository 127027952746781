import { AsyncPipe, DatePipe, JsonPipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { CardModule } from 'primeng/card';
import { combineLatest, Observable, of, ReplaySubject, startWith, switchMap } from 'rxjs';
import { Delivery } from 'src/app/models/delivery';
import { DeliveryService } from 'src/app/services/delivery.service';

/**
 * Displays a readonly view of a delivery.
 * Used for displaying historic versions of deliveries.
 */
@Component({
  selector: 'app-delivery-readonly',
  templateUrl: './delivery-readonly.component.html',
  styleUrls: ['./delivery-readonly.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ AsyncPipe, JsonPipe, DatePipe, CardModule ]
})
export class DeliveryReadonlyComponent {

  @Input({required: true}) set deliveryUid(v) { this.deliveryUidSubject.next(v) }
  private deliveryUidSubject = new ReplaySubject<string>(1)

  @Input({required: true})  set deliveryVersion(v) { this.deliveryVersionSubject.next(v) }
  public deliveryVersionSubject = new ReplaySubject<number>(1)

  public delivery$ = combineLatest({
    deliveryUid: this.deliveryUidSubject,
    deliveryVersion: this.deliveryVersionSubject
  }).pipe(
    switchMap(source => {
      if(source.deliveryUid && source.deliveryVersion) {
        return this.deliveryService.getDeliveryVersion$(source.deliveryUid, source.deliveryVersion)
      } else {
        return of(undefined)
      }
  })
)

  constructor(private deliveryService: DeliveryService) { }

  // ngOnChanges(changes: SimpleChanges): void {
  //   changes['deliveryUid'].currentValue
  // }

  // ngOnInit(): void {
  //   this.delivery$ = combineLatest({
  //     deliveryUid: this.deliveryUid$,
  //     deliveryVersion: this.deliveryVersion$
  //   }).pipe(
  //     switchMap(source => {
  //       debugger
  //       if(source.deliveryUid && source.deliveryVersion) {
  //         return this.deliveryService.getDeliveryVersion$(source.deliveryUid, source.deliveryVersion)
  //       } else {
  //         return of(undefined)
  //       }
  //   }),
  //   startWith({})
  // )}

}
