import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AsyncPipe, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { combineLatest, filter, map, switchMap } from 'rxjs';
import { Table, TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';
import { CardModule } from 'primeng/card';

import { OldDelivery } from 'src/app/models/old-delivery';
import { TestCalendarService } from 'src/app/services/testcalendar.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { SessionService } from 'src/app/services/session.service';

/**
 * Component displaying a table of deliveries.
 */
@Component({
    selector: 'app-deliveries',
    templateUrl: './deliveries.component.html',
    styleUrls: ['./deliveries.component.scss'],
    providers: [{ provide: DeliveryService }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
      TableModule,
      SharedModule,
      ButtonModule,
      InputTextModule,
      TooltipModule,
      MultiSelectModule,
      ReactiveFormsModule,
      FormsModule,
      SkeletonModule,
      CardModule,
      AsyncPipe,
      DatePipe
    ]
})
export class DeliveriesComponent {

  /**
   * ViewChild enabling access to table internals.
   */
  @ViewChild('dt') dt: Table | undefined;

  /**
   * Observable of all data used to display deliveries table.
   */
  public deliveryData$ = this.sessionService.userData$.pipe(
    filter(userData => !!userData.sessionUID),
    switchMap(() => {
      return combineLatest({
        deliveriesAndEcus: this.deliveryService.deliveriesAndEcus$,
        sops: this.testCalendarService.allSOPs$,
        testWeeks: this.testCalendarService.allTestWeeks$
      })
    }),
    map(data => {
      return {
        AvailableTestWeeks: data.testWeeks,
        Sops: data.sops,
        Ecus: data.deliveriesAndEcus.ecus,
        DeliveriesWithEcu: data.deliveriesAndEcus.deliveries.map((delivery: OldDelivery) => {
          return {
            Id: delivery.Id,
            Name: delivery.Name,
            Description: delivery.Description?.length > 100 ? delivery.Description?.substring(0, 100) + "..." : delivery.Description,
            Sop: data.sops.find((sop) => sop.Name === delivery.Sop) ?? { Name: delivery.Sop, Id: null, TypeId: 6, Description: null, StartDate: null, EndDate: null },
            Ecu: data.deliveriesAndEcus.ecus.find((ecu) => ecu.EcuId === delivery.EcuId) ?? { EcuId: delivery.EcuId, EcuName: null },
            TestWeek: this.testCalendarService.getTestWeekByStartDate(data.testWeeks, delivery.ValidFrom)
          };
        })
      }
    })
  )

    // Placeholder data for skeleton rows
    public skeletonData = Array(5).fill({});

    // Placeholder data for skeleton columns
    public skeletonColumns = Array(6).fill({});

  /**
   * Constructor.
   * @param testCalendarService To get SOPs and test weeks.
   * @param deliveryService To get deliveries and ECUs.
   * @param sessionService To check user logged in status.
   * @param router To navigate to sw-delivery page.
   */
  constructor(
    private testCalendarService: TestCalendarService,
    private deliveryService: DeliveryService,
    private sessionService: SessionService,
    private router: Router) {
  }

  /**
   * Navigates to delivery in selected row.
   * @param event Click event of table.
   */
  onRowSelect(event: any) {
    this.router.navigate(['software-delivery/' + event.data.Id])
  }

  /**
   * Clears all filters.
   * @param table Table to clear.
   */
  clearFilters(table: Table) {
    table.clear();
  }
}
