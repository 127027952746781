export const environment = {
    "production": false,
    "appSyncDdb_staging":"https://eqk665utwvh7pdkyoj4op235ce.appsync-api.eu-north-1.amazonaws.com/graphql",
    "appSyncDdb_release":"https://5iu4hrknxfcb7lpc272y65uc2i.appsync-api.eu-north-1.amazonaws.com/graphql",
    "basekey":"da2-ovoltgezzvbi7de4kgb5qklkna",
    "appconfigurl":"https://3uqouj4bwnfjpbyokxsqcptnse.appsync-api.eu-north-1.amazonaws.com/graphql",
    "configkey":"da2-vhtvy76skrekrh3f3lhyomvpem",
    "requirementsurl":"https://polarion-dev.scania.com/polarion/#/",
    "auth_client_id": "2k1a828hcuccld58ve113mjfok",
    "auth_url": "https://testtools.testtools.devtest.gf.aws.scania.com/login",
    "abstractionlayer_staging": "https://quobkvexmre7blsb7ltpdz2xxi.appsync-api.eu-north-1.amazonaws.com/graphql",
    "abstractionlayer_release": "https://5oetsllb2jfelbghdyob2uy2r4.appsync-api.eu-north-1.amazonaws.com/graphql",
    "logConfig": {
        "application": "ddb",
        "env": "dev",
        "graphqlUrl": "https://kftht5tfp5b6tfwm6kzqa7ijzu.appsync-api.eu-north-1.amazonaws.com/graphql",
        "logLevel": 2
    },
    "launchDarklyClientId": "664590c02c638010dc55095e",
  };
