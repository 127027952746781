import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, Observable, of, tap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { UserData } from 'src/app/models/user-data';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ){}

  private userDataSubject: BehaviorSubject<UserData> = new BehaviorSubject({
    sessionUID: localStorage.getItem('sessionId') ?? '',
    userName: localStorage.getItem('userName') ?? '',
    companyName: localStorage.getItem('companyName') ?? ''
  })
  public userData$ = this.userDataSubject.asObservable()

  clearSession(): void {
    this.userDataSubject.next({userName: '', sessionUID: '', companyName: ''})

    // Clear data from local storage
    localStorage.removeItem('sessionId');
    localStorage.removeItem('userName');
    localStorage.removeItem('companyName');
  }

  configureSession(sessionId: string, userName: string, companyName: string): Observable<any> {

    if (sessionId != '') {
      return of({
        sessionUID: sessionId,
        userName: userName,
        companyName: companyName,
      }).pipe(
        tap(data => {
          this.userDataSubject.next(data)
          localStorage.setItem('sessionId', data.sessionUID)
          localStorage.setItem('userName', data.userName)
          localStorage.setItem('companyName', data.companyName)
          this.removeSpecificQueryParams(['sessionId', 'userName', 'companyName'])
        })
      )
    }else {
      return of(false)
    }
  }

   /**
   * Redirects to landing page for login.
   */
   public login() {
    const host = encodeURIComponent(window.location.href)
    const loginUrl = this.getLoginUrl()
    window.location.href = loginUrl;
  }

  removeSpecificQueryParams(paramsToRemove: string[]) {
    // Get the current query parameters
    const queryParams = { ...this.route.snapshot.queryParams };

    // Remove specific parameters
    paramsToRemove.forEach(param => delete queryParams[param]);

    // Construct a new URL without the removed parameters
    const urlTree = this.router.createUrlTree([], { queryParams });

    // Navigate to the new URL
    this.router.navigateByUrl(urlTree);
  }

  getLoginUrl(): string {
    console.log(`LoginUrl=${environment.auth_url}?origin=${`${window.location.protocol}//${window.location.host}`}`)
    return `${environment.auth_url}/?origin=${`${window.location.protocol}//${window.location.host}`}`;
  }
}
