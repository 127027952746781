import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';

import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';
import { v4 as uuidv4 } from 'uuid';

import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MenubarModule, AvatarModule, RouterLink, MenuModule, ButtonModule, AsyncPipe],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

public title = 'Delivery Database'

public items = [
  {
    label: 'Deliveries',
    route: '/'
  },
  {
    label: 'New delivery',
    route: '/software-delivery/' + uuidv4(),
    queryParams: { 'new-delivery': true}
  },
]

constructor(public sessionService: SessionService) { }

}
