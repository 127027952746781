<p-menubar class="menu" [model]="items">
  <ng-template pTemplate="start">
    <a routerLink="/">
      <div class="title">{{ title }}</div>
    </a>
  </ng-template>
  <ng-template pTemplate="item" let-item>
    @if(item.route) {
      <a [routerLink]="item.route" [queryParams]="item.queryParams" class="p-menuitem-link">
        <span [class]="item.icon"></span>
        <span class="ml-2">{{ item.label }}</span>
    </a>
    } @else if (item.url) {
      <a [href]="item.url" class="p-menuitem-link">
        <span [class]="item.icon"></span>
        <span class="ml-2">{{ item.label }}</span>
    </a>
    } @else {
      <div class="p-menuitem-link">
        <span [class]="item.icon"></span>
        <span class="ml-2">{{ item.label }}</span>
        <span class="pi pi-fw pi-angle-down ml-2"></span>
    </div>
    }

</ng-template>
  <ng-template pTemplate="end">
    <div class="end-items">
      <p-menu #menu [popup]="true">
        <ng-template pTemplate="start">
          @if (sessionService.userData$ | async; as userData) {
            <div class="session-content">
              @if(!!userData.sessionUID) {
                <div><b>{{userData.userName}}</b></div>
                <div>{{userData.companyName}}</div>
              } @else {
                <a style="cursor:pointer" (click)="sessionService.login()">Login</a>
              }
            </div>
          }
        </ng-template>
      </p-menu>
      <div class="user-icon-container" style="cursor:pointer" (click)="menu.toggle($event)">
        <img class="user-icon"  src="https://www.svgrepo.com/show/384671/account-avatar-profile-user-14.svg"/>
      </div>
      <img class="traton-logo" src="theme/assets/logos/TRATON_Logo_RGB_white.png" />
    </div>
  </ng-template>
</p-menubar>
