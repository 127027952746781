import { Injectable } from '@angular/core';

import { Observable, catchError, map, of, shareReplay, switchMap, take } from 'rxjs';
import { bool } from 'aws-sdk/clients/signer';
import { v4 as uuidv4 } from 'uuid'

import { olddeliveryQueries } from '../graphql/queries/oldDeliveryQueries';
import { ApolloClientService } from 'src/app/services/apollo-client.service';
import { Delivery } from 'src/app/models/delivery';
import { DeliveryMutations } from 'src/app/graphql/mutations/delivery.mutations';
import { DeliveryQueries } from 'src/app/graphql/queries/delivery.queries';
import { convertDeliveryToGraphqlDeliveryInput, convertGrapqlDeliveryOutputToDelivery } from 'src/app/graphql/mappers/delivery.mappers';
import { GraphqlDeliveryOutput } from 'src/app/graphql/models/output/delivery.output.model';


/**
 * Service handling delivery data.
 */
@Injectable()
export class DeliveryService {

  /**
   * Get all deliveries and ECUs from date specified in function.
   */
  public deliveriesAndEcus$ = this.apolloClientService.appSyncDbClient$.pipe(
    switchMap(client =>
      this.apolloClientService.observableWatchQuery$(client, {
        query: olddeliveryQueries.oldgetDeliveryAndEcus,
        variables: { validFrom: "2022-08-14 08:00:00" },
        errorPolicy: "all"
      })
    ),
    map((res: any) => {
      return {deliveries: res.data.getDeliveries, ecus: res.data.getAllEcus.slice().sort((a, b) => a.EcuName.toLowerCase().localeCompare(b.EcuName.toLowerCase()))}
    }),
    shareReplay(1) // Share results with all subscribers, including late subscribers.
  )

  /**
   * Constructor.
   * @param apolloClientService To make graphql requests.
   */
  constructor(private apolloClientService: ApolloClientService) { }

  /**
   * Gets details about input delivery.
   * @param deliveryUid UID of delivery to get.
   * @returns Observable of delivery with input UID.
   */
  public getDelivery$(deliveryUid: string): Observable<Delivery | undefined> {

    return this.apolloClientService.abstractionLayerClient$.pipe(
      switchMap(client =>
        client.query<{delivery: GraphqlDeliveryOutput}>({
          query: DeliveryQueries.getDeliveryQuery,
          variables: { UID: deliveryUid }
        }),
      ),
      map(result => convertGrapqlDeliveryOutputToDelivery(result.data.delivery))
    )
  }

/**
 * Gets all versions of a delivery.
 * @param deliveryUid UID of delivery to get.
 * @returns Observable of all versions of delivery.
 */
  public getDeliveryVersions$(deliveryUid: string): Observable<Delivery[]> {

    return this.apolloClientService.abstractionLayerClient$.pipe(
      switchMap(client =>
        client.query<{deliveryVersions: GraphqlDeliveryOutput[]}>({
          query: DeliveryQueries.getDeliveryVersionsQuery,
          variables: { UID: deliveryUid }
        }),
      ),
      map(result => {
        if (result.data.deliveryVersions) {
          return result.data.deliveryVersions.map(item => convertGrapqlDeliveryOutputToDelivery(item)).filter(item => !!item) as Delivery[]
        } else {
          return []
        }
      })
    )
  }

  /**
   * Get a specific version of a delivery.
   * @param deliveryUid UID of delivery to get.
   * @param version Version of delivery to get.
   * @returns Observable of delivery with input UID.
   */
  public getDeliveryVersion$(deliveryUid: string, version: number): Observable<Delivery | undefined> {

    return this.apolloClientService.abstractionLayerClient$.pipe(
      switchMap(client =>
        client.query<{deliveryVersion: GraphqlDeliveryOutput}>({
          query: DeliveryQueries.getDeliveryVersionQuery,
          variables: { UID: deliveryUid, Version: version }
        }),
      ),
      map(result => convertGrapqlDeliveryOutputToDelivery(result.data.deliveryVersion))
    )
  }

  /**
   * Saves a delivery.
   * @param delivery Delivery to save.
   * @returns True if save successful, false otherwise.
   */
  public saveDelivery$(delivery: Delivery): Observable<bool> {

    const input = convertDeliveryToGraphqlDeliveryInput(delivery)

    if (!input) {
      return of(false)
    }

    return this.apolloClientService.abstractionLayerClient$.pipe(
      switchMap(client => client.mutate<bool>({
        mutation: DeliveryMutations.saveDeliveryMutation,
        variables: { Delivery: input }
      })),
      map(result => !!result.data),
      take(1),
      catchError(() => of(false))
    )
  }
}
