<div class="deliveries-container">
  <p-card>
    @if(deliveryData$ | async; as data) {
      <p-table
          #dt1
          [value]="data.DeliveriesWithEcu"
          dataKey="Id"
          [rows]="5"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[5, 10, 15]"
          [paginator]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [globalFilterFields]="['Id','Name', 'Ecu.EcuName', 'Ecu.EcuId', 'Description', 'Sop.Name', 'TestWeek.Name', 'TestWeek.Id']"
          selectionMode="single"
          (onRowSelect)="onRowSelect($event)"
      >
          <ng-template pTemplate="caption">
              <!-- <i class="scania-icon-filter"></i>Example Scania filter icon -->
              <div class="flex" style="overflow:hidden">
                  <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clearFilters(dt1)"></button>
                  <span class="p-input-icon-left ml-auto" style="float:right">
                      <i class="pi pi-search"></i>
                      <input pInputText #textInput type="text" (input)="dt1.filterGlobal(textInput.value, 'contains')" placeholder="Search keyword" />
                  </span>
              </div>
          </ng-template>
          <ng-template pTemplate="header">
              <tr>
                  <th style="min-width:15rem" pSortableColumn="Id" style="width:20%">
                      <div class="header-item">
                          Id <p-sortIcon field="Id"></p-sortIcon>
                          <p-columnFilter type="text" field="Id" display="menu" [hideOnClear]="true"></p-columnFilter>
                      </div>
                  </th>
                  <th style="min-width:15rem" pSortableColumn="Name" style="width:20%">
                      <div class="header-item">
                          Name <p-sortIcon field="Name"></p-sortIcon>
                          <p-columnFilter type="text" field="Name" display="menu" [hideOnClear]="true"></p-columnFilter>
                      </div>
                  </th>
                  <th style="min-width:15rem" pSortableColumn="Ecu.EcuName" style="width:20%" pTooltip="Electronic Control Unit" tooltipPosition="top">
                      <div class="header-item">
                          ECU <p-sortIcon field="Ecu.EcuName"></p-sortIcon>
                          <p-columnFilter field="Ecu" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [hideOnClear]="true">
                              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                  <p-multiSelect [ngModel]="value" [options]="data.Ecus" placeholder="Any" (onChange)="filter($event.value)" optionLabel="EcuName">
                                      <ng-template let-option pTemplate="item">
                                          <div>
                                              <span class="ml-1 mt-1">{{ option.EcuName }}</span>
                                          </div>
                                      </ng-template>
                                  </p-multiSelect>
                              </ng-template>
                          </p-columnFilter>
                      </div>
                  </th>
                  <th style="min-width:15rem">
                      <div class="header-item">
                          Description
                          <p-columnFilter type="text" field="Description" display="menu" [hideOnClear]="true"></p-columnFilter>
                      </div>
                  </th>
                  <th style="min-width:15rem" pSortableColumn="Sop.Name" style="width:20%" pTooltip="Start of Production" tooltipPosition="top">
                      <div class="header-item">
                          SOP <p-sortIcon field="Sop.Name"></p-sortIcon>
                          <p-columnFilter field="Sop" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [hideOnClear]="true">
                              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                  <p-multiSelect [ngModel]="value" [options]="data.Sops" placeholder="Any" (onChange)="filter($event.value)" optionLabel="Name">
                                      <ng-template let-option pTemplate="item">
                                          <div>
                                              <span class="ml-1 mt-1">{{ option.Name }}</span>
                                          </div>
                                      </ng-template>
                                  </p-multiSelect>
                              </ng-template>
                          </p-columnFilter>
                      </div>
                  </th>
                  <th style="min-width:15rem" pSortableColumn="TestWeek.Name" style="width:20%">
                      <div class="header-item">
                          Test Week <p-sortIcon field="TestWeek.Name"></p-sortIcon>
                          <p-columnFilter field="TestWeek" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [hideOnClear]="true">
                              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                  <p-multiSelect [ngModel]="value" [options]="data.AvailableTestWeeks" placeholder="Any" (onChange)="filter($event.value)" optionLabel="Name">
                                      <ng-template let-option pTemplate="item">
                                          <div>
                                              <span class="ml-1 mt-1">{{ option.Name }}</span>
                                          </div>
                                      </ng-template>
                                  </p-multiSelect>
                              </ng-template>
                          </p-columnFilter>
                      </div>
                  </th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-deliveryWithEcu>
              <tr [pSelectableRow]="deliveryWithEcu">
                  <td>
                      {{ deliveryWithEcu.Id }}
                  </td>
                  <td>
                      {{ deliveryWithEcu.Name }}
                  </td>
                  <td>
                      {{ deliveryWithEcu.Ecu.EcuName }}
                  </td>
                  <td>
                      <div [innerHTML]="deliveryWithEcu.Description"></div>
                  </td>
                  <td>
                      {{ deliveryWithEcu.Sop.Name }}
                  </td>
                  <td pTooltip="Start Date: {{ deliveryWithEcu.TestWeek.StartDate | date: 'EEEE, y, MMMM d, h:mm zzzz' }} &#013;&#010; End Date: {{ deliveryWithEcu.TestWeek.EndDate | date: 'EEEE, y, MMMM d' }}">
                      {{ deliveryWithEcu.TestWeek.Name }}
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
              <tr>
                  <td colspan="7">No deliveries found</td>
              </tr>
          </ng-template>
      </p-table>
    } @else {
      <p-table [value]="skeletonData">
        <ng-template pTemplate="header">
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>ECU</th>
            <th>Description</th>
            <th>SOP</th>
            <th>Test Week</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            @for (col of skeletonColumns; track col) {
              <td>
                <p-skeleton width="100%" height="120px"></p-skeleton>
              </td>
            }
          </tr>
        </ng-template>
      </p-table>
    }
  </p-card>
</div>
