import { gql } from "apollo-angular";

export class DeliveryQueries {

  public static getDeliveryQuery = gql`query delivery($UID: ID!) {
    delivery(UID: $UID) {
      Description
      LatestVersion
      RegBy
      Name
      RegTime
      UID
      Version
    }
  }`

  public static getDeliveryVersionsQuery = gql`query deliveryVersions($UID: ID!) {
    deliveryVersions(UID: $UID) {
        Description
        LatestVersion
        RegBy
        Name
        RegTime
        UID
        Version
      }
    }`

  public static getDeliveryVersionQuery = gql`query deliveryVersion($UID: ID!, $Version: Int!) {
    deliveryVersion(UID: $UID, Version: $Version) {
        Description
        LatestVersion
        RegBy
        Name
        RegTime
        UID
        Version
      }
    }`

}
