import { Routes } from '@angular/router';

import { DeliveriesComponent } from 'src/app/components/delivery/deliveries/deliveries.component';
import { SwDeliveryComponent } from 'src/app/components/delivery/sw-delivery/sw-delivery.component';
import { SettingsComponent } from 'src/app/components/settings/settings/settings.component';


export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: DeliveriesComponent
      },
      {
        path: 'software-delivery/:id',
        component: SwDeliveryComponent
      },
      {
        path: 'software-delivery',
        component: SwDeliveryComponent
      },
    ]
  },
  {
    path: 'deliveries',
    children: [
      {
        path: '',
        component: DeliveriesComponent
      },
      {
        path: 'software-delivery',
        component: SwDeliveryComponent
      },
    ]
  },
  {
    path: 'settings',
    children: [
      {
        path: '',
        component: SettingsComponent
      }
    ]
  },
];
