<p-card class="container">
  <h2 class="header">Admin</h2>
  <form class="form-items" [formGroup]="testweekForm">
    <div class="input-container">
      <label for="id">Test week Id</label>
      <input id="id" type="text" pInputText formControlName="id"/>
    </div>
    <div class="input-container">
      <label for="name">Test week name</label>
      <input id="name" type="text" pInputText formControlName="name"/>
    </div>
    <p-button label="Submit" [disabled]="!testweekForm.valid"></p-button>
  </form>
</p-card>
