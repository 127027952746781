import { gql } from 'apollo-angular';

export class testCalendarQueries {

  //SQLServer
  public static getAllTestCalendarsByTypeQuery = gql`
  query testCalendarsByType($TypeId: Int!) {
    testCalendarsByType(TypeId: $TypeId) {
      Description
      EndDate
      Name
      StartDate
      TypeId
      Id
    }
  }`

  //DynamoDB
  public static getAllTestWeeksQuery = gql`
    query getAllTestWeeks {
      getAllTestWeeks {
        Id
        Name
        StartUtc
        EndUtc
      }
    }`

    public static getAllSopsQuery = gql`
    query getAllSops {
      getAllSops {
        Id
        Name
      }
    }`

};
