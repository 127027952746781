import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router, RouterOutlet } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { AsyncPipe } from '@angular/common';

import { Observable, combineLatest, filter, map, shareReplay, switchMap } from 'rxjs';

import { SessionService } from './services/session.service';
import { LaunchDarklyService } from './services/launchdarkly.service';
import { HeaderComponent } from 'src/app/components/header/header.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, AsyncPipe, HeaderComponent]
})
export class AppComponent implements OnInit {
  title = 'early-delivery-client';
  private destroyRef = inject(DestroyRef)
  params$: Observable<ParamMap> = this.route.queryParamMap
  navigationEnded$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));

  admin$ = this.launchDarklyService.launchDarklyFlags$.pipe(
    map(flags => flags['admin']),
    shareReplay(1)
  )

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private router: Router,
    private route: ActivatedRoute,
    public sessionService: SessionService
  ) { }

  ngOnInit(): void {

    this.sessionService.userData$.pipe(
      filter(userData => !userData.sessionUID),
      switchMap(() =>
        combineLatest({
          params: this.params$,
          navigationEnded: this.navigationEnded$,
        }).pipe(
          switchMap((result) => {
            const sessionId = result.params.get('sessionId') ?? ""
            const userName = result.params.get('username') ?? ""
            const companyName = result.params.get('company') ?? ""
            return this.sessionService.configureSession(sessionId, userName, companyName)
          }),
          )
        ),
        takeUntilDestroyed(this.destroyRef)
    ).subscribe()
  }
}

