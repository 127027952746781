import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

    formatDate(date: Date | undefined):string | null {
        if (!date) {
          return ''
        }

        var val = formatDate(date, 'yyyy-MM-dd hh:mm:ss', 'en');
        return val != null ? val : "";
    }
}
