import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ErrorHandler, importProvidersFrom } from '@angular/core';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { LOGGER_CONFIG, ErrorHandlerService } from 'tmt-logger';
import { LaunchDarklyService } from './app/services/launchdarkly.service';
import { provideRouter } from '@angular/router';
import { routes } from './app/routes/routes';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXhfcXRdR2ZeU0R2WkI=')


bootstrapApplication(AppComponent, {
    providers: [
        provideRouter(routes),
        importProvidersFrom(BrowserModule),
        LaunchDarklyService,
        {
            provide: LOGGER_CONFIG,
            useValue: of(environment.logConfig),
        },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
    ]
})
  .catch(err => console.error(err));

