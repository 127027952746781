@if(delivery$ | async; as delivery) {
  <h2 class="header">{{!!delivery.UID ? "DeliveryId: " + delivery.UID : "New delivery" }}</h2>

  <div class="form-items-container">
    <div>
      Name: {{delivery.Name}}
    </div>
    <div>
      Version: {{delivery.Version}}
    </div>
    <div>
      Latest update: {{delivery.RegTime | date: 'yyyy-MM-dd HH:mm:ss'}}
    </div>
    <div>
      Updated by: {{delivery.RegBy}}
    </div>
    <div>
      Description:
      <p-card>

        <div class="description-container" [innerHTML]="delivery.Description">
        </div>
      </p-card>
    </div>
  </div>
  }



