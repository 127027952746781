import { AsyncPipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { HtmlEditorService, ImageService, LinkService, RichTextEditorModule, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { combineLatest, filter, map, of, ReplaySubject, shareReplay, switchMap, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { Delivery } from 'src/app/models/delivery';
import { DeliveryService } from 'src/app/services/delivery.service';
import { TestCalendarService } from 'src/app/services/testcalendar.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SystemGeneration } from './../../../enums/system-generation.enum';
import { DeliveryReadonlyComponent } from "../delivery-readonly/delivery-readonly.component";

/**
 * Component displaying a form for editing a delivery.
 */
@Component({
    selector: 'app-sw-delivery',
    templateUrl: './sw-delivery.component.html',
    styleUrls: ['./sw-delivery.component.scss'],
    providers: [{ provide: DeliveryService }, ToolbarService, LinkService, ImageService, HtmlEditorService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    ReactiveFormsModule,
    InputTextModule,
    AsyncPipe,
    RichTextEditorModule,
    CardModule,
    ProgressSpinnerModule,
    ButtonModule,
    DropdownModule,
    TableModule,
    JsonPipe,
    RouterLink,
    DeliveryReadonlyComponent
]
})
export class SwDeliveryComponent implements OnInit {

  public systemGenerationOptions = Object.values(SystemGeneration);

  public showHistory: boolean = false;

  /**
   * Form for editing delivery.
   */
  public deliveryForm = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    validFrom:  [''],
    sop:  [''],
    ecuId:  [''],
    testWeek: [''],
    systemGeneration: [''],
    identifiers: this.fb.array([]),
    uid: ['']
  });

  // Form control for currently selected version.
  public selectedVersion = new FormControl<number | null>(null);
  public selectedVersion$ = this.selectedVersion.valueChanges.pipe(shareReplay(1))

  get identifiersArray(): FormArray {
    return this.deliveryForm.get('identifiers') as FormArray;
  }

  /**
   * Get all test weeks and SOPs to populate dropdowns.
   */
  public testWeeks$ = this.testCalendarService.allTestWeeks$;
  public SOPs$ = this.testCalendarService.allSOPs$;

  /**
   * Observable of delivery data.
   */
  public delivery$ = combineLatest({
    id: this.route.params.pipe(map(params => params['id'])),
    isNew: this.route.queryParams.pipe(map(queryParams => queryParams['new-delivery'] === 'true')),
    version: this.route.queryParams.pipe(map(queryParams => queryParams['version']))
  }).pipe(
    switchMap(source => {
      if(source.id && !source.isNew) {
        return this.deliveryService.getDelivery$(source.id)
      } else if (source.id && source.isNew) {
        return of({
          UID: source.id,
          Name: 'New Delivery',
          Description: ''
        })
      } else {
        this.router.navigateByUrl('/software-delivery/' + uuidv4() + '?new-delivery=true')
        return of({})
      }
    }),
    filter(delivery => !!delivery),
    // TODO STUB Temporary ECU identifiers since we don't fetch them yet.
    map(delivery => {
      return {
        ...delivery,
        Identifiers: [
          {Description: 'stub', ID: 1, Value: ''},
          {Description: 'stub', ID: 2, Value: 'fjdsk'},
          {Description: 'stub', ID: 3, Value: 'asdfghzxc'}
        ]
      }
    }),
    tap(delivery => {
        this.updateForm(delivery)
    }),
    shareReplay(1)
  )

  // Observable of all available versions of current delivery.
  public versions$ = this.delivery$.pipe(
    map(data => {
      const version = data.LatestVersion ?? 0
      return Array.from({length: version - 1 }, (_, index) => index + 1)

    })
  )

  /**
   *
   * @param route To get delivery ID from route.
   * @param fb To build component form.
   * @param utilsService To format date correctly.
   * @param deliveryService To get delivery data.
   */
  constructor(
    public route :ActivatedRoute,
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private deliveryService: DeliveryService,
    private testCalendarService: TestCalendarService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if(this.route.snapshot.queryParams['new-delivery'] !== 'true') {
      this.deliveryForm.disable()
    } else {
      this.deliveryForm.enable()
    }
  }

  /**
   * Takes a delivery object and patches the component form with its values.
   * @param delivery The delivery to display.
   */
  updateForm(delivery: Delivery | undefined) {

    // if (delivery?.Identifiers?.length) {
    //   delivery.Identifiers.forEach(item => {
    //     (this.deliveryForm.controls['identifiers'] as FormArray).push(new FormGroup({
    //       Description: new FormControl<string>(item.Description),
    //       ID: new FormControl<number>(item.ID),
    //       Value: new FormControl<string>(item.Value)
    //     }))
    //   })
    // }

    this.deliveryForm.patchValue({
      name: delivery?.Name,
      description: delivery?.Description,
      uid: delivery?.UID
      // validFrom: this.utilsService.formatDate(delivery?.ValidFrom),
      // sop: delivery?.Sop,
      // ecuId: delivery?.EcuId
    });

    const currentName = this.deliveryForm.get('name')?.value;
    console.log("current name: ", currentName);
    const currentValues = this.deliveryForm.value;
    console.log("current values: ", currentValues);
  }

  public onSubmit(): void {
    if(this.deliveryForm.dirty) {
      const formValue = this.deliveryForm.value;

      const newDelivery = {
        UID: formValue.uid,
        Name: formValue.name,
        Description : formValue.description
      } as Delivery;

      this.deliveryService.saveDelivery$(newDelivery).pipe(
        tap(result => {
          if (result && this.route.snapshot.queryParams['new-delivery']) {
            // Remove "is new" query param if applicable, when delivery has been saved.
            this.router.navigate(
              [],
              {
                relativeTo: this.route,
                queryParams: {
                  'new-delivery': null
                },
                queryParamsHandling: 'merge',
                replaceUrl: true
              }
            );
          }
        })
      ).subscribe();
    }
  }

  public toggleFormDisabled() {
    this.deliveryForm.disabled ? this.deliveryForm.enable() : this.deliveryForm.disable();
  }

  public toggleShowHistory() {
    this.showHistory = !this.showHistory;
  }

  public newValues() {
    this.deliveryForm.valueChanges.subscribe(value => {
      console.log("new values: ", value)
    })

    this.deliveryForm.get('name')?.valueChanges.subscribe(nameValue => {
      console.log("new name: ", nameValue)
    })
  }
}
