<div class="delivery-container">

  <p-card>
    <app-delivery-readonly [hidden]="!showHistory" [deliveryUid]="(delivery$ |async)?.UID" [deliveryVersion]="selectedVersion$ | async"></app-delivery-readonly>
  </p-card>

  <p-card>
    @if(delivery$ | async; as delivery) {
    <form [formGroup]="deliveryForm" (ngSubmit)="onSubmit()">
      <h2 class="header">{{!!delivery.UID ? "DeliveryId: " + delivery.UID : "New delivery" }}</h2>

      <div class="form-items-container">

        <div class="versions-container">
          Version: {{delivery.LatestVersion}}

          @if(delivery?.LatestVersion ?? 0 > 1) {
            @if (delivery.LatestVersion !== 1) {
                <p-dropdown class="versions-dropdown"
                [formControl]="selectedVersion"
                [options]="(versions$ | async) ?? []"
                placeholder="Select to compare versions"
                [showClear]="true"
                ></p-dropdown>

                <p-button
                [label]="showHistory ? 'Disable history' : 'Enable history'"
                (onClick)="toggleShowHistory()"
                [disabled]="((selectedVersion.valueChanges | async) ?? 0) < 1"
                ></p-button>
            }
          }
        </div>

        <div>
          <div class="p-float-label">
            <input type="text" pInputText formControlName="name" />
            <label for="name">Name</label>
          </div>
        </div>

        <div>
          <div class="multiple-inputs-container">
            <div class="p-float-label">
              <p-dropdown [options]="SOPs$ | async"
              optionLabel="Name" optionValue="Name"
              formControlName="sop"></p-dropdown>
              <label for="sop">SOP</label>
            </div>

            <div class="p-float-label">
              <p-dropdown [options]="testWeeks$ | async"
              optionLabel="Name" optionValue="Name"
              formControlName="testWeek"></p-dropdown>
              <label for="testWeek">Test week</label>
            </div>
          </div>
        </div>

        <div>
          <div class="p-float-label">
            <p-dropdown
              [options]="systemGenerationOptions"
              formControlName="systemGeneration"
            ></p-dropdown>
            <label for="systemGeneration">System Generation</label>
          </div>
        </div>

        <div class="multiple-inputs-container">
          <div>ECU Id: {{ deliveryForm.get("ecuId")?.value }}</div>
          <div>Valid from: {{ deliveryForm.get("validFrom")?.value }}</div>
        </div>

        <div>
          <label>ECU Identifiers</label>
          <div formArrayName="identifiers">
            <p-table [value]="identifiersArray.controls">
              <ng-template pTemplate="header">
                <tr>
                  <th>Identifier description</th>
                  <th>Identifier</th>
                  <th>Value</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-row let-i="rowIndex">
                <tr [formGroupName]="i">
                  <td>{{ row.get("Description").value }}</td>
                  <td>{{ row.get("ID").value }}</td>
                  <td>
                    <input pInputText formControlName="Value" />
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>

        <div>
          <label>Description</label>
          <ejs-richtexteditor
            height="500px"
            id="defaultRTE"
            formControlName="description"
          >
          </ejs-richtexteditor>
        </div>

        <div class="buttons-container">
          <p-button (onClick)="toggleFormDisabled()">
            {{ deliveryForm.disabled ? "Enable" : "Disable" }} editing
          </p-button>

          <div class="spacer"></div>

          <a routerLink="/">
            <p-button label="Cancel" severity="info"></p-button>
          </a>

          <p-button type="submit"> Save </p-button>
          <p-button (onClick)="newValues()"> newValues </p-button>
        </div>
      </div>
    </form>
    } @else {
    <p-progressSpinner></p-progressSpinner>
    }
  </p-card>

</div>
